import React, {useState, useRef, useEffect} from 'react';
import {ReactComponent as BurgerIcon} from "../../images/icons/burger.svg";
import {ReactComponent as CloseIcon} from "../../images/icons/burger-close-white.svg";
import Links from "../header/links";



const MobileMenu = ({mobile = false}) => {
    const [open,setOpen] = useState(false)



    const handleClickLink = (clickL) => {
        if (clickL) {
            handleOpen()
        }
    }

    const handleOpen = () => {
        setOpen(!open)
        if(open) {
            document.body.classList.remove('hideScrollY')

        } else {
            document.body.classList.add('hideScrollY')
        }
    }
    useEffect(()=>{
        const header = document.querySelector('header')
        const mobileContainer = document.querySelector('.mobileMenuContainer')
        mobileContainer.style.cssText += 'top:' + header.offsetHeight + 'px'
    },[])


    return (
        <div>
            <div onClick={()=> {
                handleOpen()
            }}>


                {open ?
                    <CloseIcon /> :
                    <BurgerIcon />
                }
            </div>
            <div className={`absolute mobileMenuContainer ${open ? 'open' : ''}`}>
                <div className="mobileLinks h-full overflow-y-scroll">
                    <Links mobile={true} onClickLink={handleClickLink}/>
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;