import React, {useState} from 'react';
import Wrapper from "../../components/wrapper";
import {ReactComponent as Team} from "../../images/aboutUs/team.svg";
import CustomButton from "../../components/button";
import manImage from '../../images/aboutUs/fullAccessMan.jpg'
import {ReactComponent as Results} from "../../images/aboutUs/results.svg";
import Logo from "../../images/logo.png";
import Button from "../../components/button";
import {TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import CustomCarousel from "../../components/carousel";
import CySec from "../../images/certificates/CYSEC.png";
import {useTranslation} from "react-i18next";

const AboutUs = () => {
    const siteName = process.env.REACT_APP_PROJECT_NAME
    const manName = process.env.REACT_APP_MAIN_MEN_NAME
    const handleValue = (event) => {
        setType(event.target.value)
    }
    const [t, i18n] = useTranslation("global");
    const [type,setType] = useState()
    return (
        <>
        <section className="AboutUs flex h-full mt-20">
            <Wrapper>
                <div className="flex w-full flex-col xl:flex-row justify-around items-center">
                    <img src={Logo}  className='w-6/12 xl:w-2/12 xl:mb-0 mb-20' />
                    <div className="freeCons flex flex-col justify-center w-7/12 xl:w-2/5 h-full items-center xl:items-start">
                        <h2 className='text-5xl text-secondFontColor text-left font-bold whitespace-pre-wrap'>{t("about_us.title").replace('siteName',siteName)}</h2>
                        <h3 className='mt-7 mb-7 text-center xl:text-left text-grayFontColor'>{t("about_us.about_us.content").replace('siteName',siteName)}</h3>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row-reverse w-full justify-around items-center mt-28">
                    <img src={manImage} className='w-6/12 xl:w-3/12 relative xl:mb-0 mb-20 rounded-2xl xl:rounded-full xl:pr-6 xl:right-10 '/>
                    <div className="freeCons flex flex-col justify-center w-7/12 xl:w-2/5 h-full items-center xl:items-start">
                        <h2 className='text-5xl text-secondFontColor text-center xl:text-left font-bold whitespace-pre-wrap'>{t("about_us.our_ceo.title").replace('siteName',siteName)}</h2>
                        <h3 className='mt-7 mb-7 text-center xl:text-left text-grayFontColor'>{t("about_us.our_ceo.content").replace('siteName',siteName).replace('manName',manName)}</h3>
                    </div>
                </div>
                <div className='flex flex-col items-center justify-center text-mainBGColor text-2xl text-center mt-24 w-8/12 mx-auto'>
                    Justice doesn't only mean that the people who commit crime are punished. It also means that we can never give up seeking the truth.
                    <span className="font-light mt-10 text-xl">-Henning Mankell</span>
                </div>
                <div className="flex w-full flex-col xl:flex-row justify-around items-center mt-24">
                    <Team  className='w-6/12 xl:w-2/12 xl:mb-0 mb-20' />
                    <div className="freeCons flex flex-col justify-center w-7/12 xl:w-2/5 h-full items-center xl:items-start">
                        <h2 className='text-5xl text-secondFontColor text-left font-bold whitespace-pre-wrap'>{t("about_us.our_team.title").replace('siteName',siteName)}</h2>
                        <p className='mt-7 mb-7 text-center xl:text-left text-grayFontColor'>{t("about_us.our_team.contents.1").replace('siteName',siteName)}</p>
                        <p className='mt-7 mb-7 text-center xl:text-left text-grayFontColor'>{t("about_us.our_team.contents.2").replace('siteName',siteName)}</p>
                        <p className='mt-7 mb-7 text-center xl:text-left text-grayFontColor'>{t("about_us.our_team.contents.3").replace('siteName',siteName)}</p>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row-reverse w-full justify-around items-center mt-28">
                    <Results className='w-6/12 xl:w-3/12 relative xl:mb-0 mb-20' />
                    <div className="freeCons flex flex-col justify-center w-7/12 xl:w-2/5 h-full items-center xl:items-start">
                        <h2 className='text-5xl text-secondFontColor text-center xl:text-left font-bold whitespace-pre-wrap'>{t("about_us.our_result.title").replace('siteName',siteName)}</h2>
                        <h3 className='mt-7 mb-7 text-center xl:text-left text-grayFontColor'>{t("about_us.our_result.content").replace('siteName',siteName)}</h3>
                    </div>
                </div>
            </Wrapper>
        </section>
    <section className='getFreeCons h-full bg-mainBGColor w-full flex flex-row pt-10 pb-10'>
        <Wrapper margins={false}>
            <div className="flex h-96 items-center justify-center">
                <div className="flex h-full xl:h-96 items-center justify-center">
                    <div className="freeCons flex flex-col justify-center w-10/12 xl:w-2/4 h-full items-start">
                        <h2 className='text-5xl font-bold text-center mx-auto'>{t("home.body.moneyBack.title")} </h2>
                        <h3 className='mt-7 mb-7 text-center mx-auto'>{t("home.body.moneyBack.subtitle")}</h3>
                        <div className="chooseScamType flex flex-col w-8/12 mx-auto">
                            <TextField
                                className='mainSelect'
                                variant='filled'
                                label={t("form.selecttypescam")}
                                inputProps={{MenuProps: {disableScrollLock: true}}}
                                onChange={handleValue}
                                // className='px-9 py-3 mb-12 pr-2.5 text-white'
                                value={type}
                                select
                                placeholder='Choose a scam type'>
                                <MenuItem value={'Binary options'}>{t("header.links.recovery.contents.binary.title")}</MenuItem>
                                <MenuItem value={'Cryptocurrency'}>{t("header.links.recovery.contents.crypto.title")}</MenuItem>
                                <MenuItem value={'Forex'}>{t("header.links.recovery.contents.forex.title")}</MenuItem>
                                <MenuItem value={'Stock Trading'}>{t("header.links.recovery.contents.stock.title")}</MenuItem>
                                <MenuItem value={'Credit card'}>{t("header.links.recovery.contents.card.title")}</MenuItem>
                                <MenuItem value={'Property Scams'}>{t("header.links.recovery.contents.property.title")}</MenuItem>
                                <MenuItem value={'Romance Scam'}>{t("header.links.recovery.contents.romance.title")}</MenuItem>
                                <MenuItem value={'Other Scams'}>{t("header.links.recovery.contents.other.title")}</MenuItem>
                            </TextField>

                            <Button filled={true} btnwidth={''} text={t("form.getFreeCons")} typeScam={type}/>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    </section>
    {/* <section className="certificates">
        <Wrapper>
            <div className="carouselCertificate h-full text-center mt-12 mb-12">
                <h2 className='ourClient text-4xl text-secondFontColor font-semibold'>
                    Certificates
                </h2>
                <div className="h-full">
                    <CustomCarousel widthContainer={'100%'} heightContainer={'100%'} sldShow={3} sldScroll={3}>
                        <img src={CySec} alt=""/>
                        <img src={CySec} alt=""/>
                        <img src={CySec} alt=""/>
                        <img src={CySec} alt=""/>
                        <img src={CySec} alt=""/>
                        <img src={CySec} alt=""/>
                        <img src={CySec} alt=""/>
                        <img src={CySec} alt=""/>
                        <img src={CySec} alt=""/>
                        <img src={CySec} alt=""/>
                        <img src={CySec} alt=""/>
                        <img src={CySec} alt=""/>
                    </CustomCarousel>
                </div>
            </div>
        </Wrapper>
    </section> */}
    </>
    );
};

export default AboutUs;