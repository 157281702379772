import React, {useLayoutEffect, useState} from 'react';
import ClientForm from '../../components/form'
import Wrapper from "../../components/wrapper";
import CustomButton from "../../components/button";
import Button from "../../components/button";
import {ReactComponent as Guarante} from "../../images/guarante.svg";
import {ReactComponent as VisaDesk} from "../../images/icons/mainPng/visa_desktop.svg";
import {ReactComponent as MastDesk} from "../../images/icons/mainPng/mastercard_desktop.svg";
import {ReactComponent as HandsSvg} from "../../images/hands.svg";
import {ReactComponent as TeamSvg} from "../../images/team.svg";
import {ReactComponent as Binary} from "../../images/icons/scamType/binary.svg";
import {ReactComponent as Cryptocurrency} from "../../images/icons/scamType/cryptocurrency.svg";
import {ReactComponent as Forex} from "../../images/icons/scamType/forex.svg";
import {ReactComponent as Romance} from "../../images/icons/scamType/romance.svg";
import {ReactComponent as Property} from "../../images/icons/scamType/property.svg";
import {ReactComponent as Creditcard} from "../../images/icons/scamType/creditcard.svg";
import {ReactComponent as Stocktrading} from "../../images/icons/scamType/stocktrading.svg";
import {ReactComponent as Otherscams} from "../../images/icons/scamType/otherscams.svg";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {TextField} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomCarousel from "../../components/carousel";
import MenuItem from "@mui/material/MenuItem";
import {Link} from "react-router-dom";

import {useTranslation} from "react-i18next";


const Main = () => {
    const [t, i18n] = useTranslation("global");
    const [mobile, setMobile] = useState(false)
    const [expanded, setExpanded] = React.useState('panel1');
    const [type, setType] = useState('')
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    useLayoutEffect(()=>{
        if(window.innerWidth <= 1366) {
            setMobile(true)
        }

    },[])
    const handleValue = (event) => {
        setType(event.target.value)
    }
    const siteName = process.env.REACT_APP_PROJECT_NAME
    return (
        <>
            <section className={`mx-auto mt-20 flex h-full  bg-headerColor md:MainMobileTitle xl:mainPageSection`}>
                <Wrapper classes={'w-full xl:w-screen justify-center items-center mt-12'} margins={'xl:mt-24 mb-24'}>
                    <div className={`flex  ${mobile ? 'flex-col justify-center' : 'justify-between'} w-full`}>
                        <div className="topSection h-auto  flex flex-col w-full xl:w-8/12  text-center xl:text-left  xl:pl-12">
                            <div className={`layoutTitle flex flex-col h-full items-center justify-center   xl:justify-stretch xl:items-stretch pb-12`}>
                                <h1 className="text-accentFontColor font-extrabold text-6xl xl:text-7xl whitespace-pre-line w-8/12 xl:w-auto">
                                    {t("home.body.top.titleYellow")} <span className='text-white'>{t("home.body.top.titleWhite")}</span>
                                </h1>
                                <h3 className='text-white w-8/12 text-lg pt-5'> {t("home.body.top.subtitle")} </h3>
                                <div style={ mobile ? {width:"100%", paddingLeft:'0', paddingRight:'0'} : {maxWidth:'100%'}} className={` clientFormMainMobile items-center justify-center flex xl:hidden`}><ClientForm inMainPage={true}/></div>
                                <div className="chooseScamType flex w-4/12 xl:hidden mb-10">
                                    <VisaDesk className='relative  ml-4 mr-4'/>
                                    <MastDesk className='relative '/>
                                </div>
                            </div>
                            <div className="chooseScamType hidden w-4/12 mt-10 xl:flex">
                                <Guarante className='stamap relative top-36 xl:top-36'></Guarante>
                                <VisaDesk className='relative top-40 ml-4 mr-4'/>
                                <MastDesk className='relative top-40'/>
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </section>
            <div className={`wrapperForm hidden xl:flex`}>
                <div className={`hidden xl:block clientFormMain`}><ClientForm inMainPage={true}/></div>
            </div>
            <section className='howItWorks bg-secondBGColor h-full text-mainBGColor flex flex-col mt-12 mb-12 bg-grayBGColor p-8'>
                <Wrapper margins={'xl:my-24'}>
                    <div className="carouselContainer h-full text-center xl:mt-12 mb-12">
                        <h2 className='ourClient text-2xl font-semibold'>
                            {t("home.body.howItWorks.carouselTitle")}
                        </h2>
                        <CustomCarousel sldShow={4} sldScroll={4} />

                    </div>
                    <div className="howItWorks  w-full text-mainBGColor flex flex-col mt-14 items-center justify-center">
                        <h1 className='text-4xl pb-10 font-bold'>{t("home.body.howItWorks.howItTitle")}</h1>
                        <div className="flex flex-col xl:flex-row items-center justify-center w-10/12">
                            <div className="howItWorks2 xl:w-2/5 w-10/12  m-5 p-10 shadow-md bg-grayBGColor w-full">
                                <h2 className={`text-xl font-bold before:content-fileFindIcon before:pr-2 items-center flex`}>
                                    {t("home.body.howItWorks.cards.review.title")}</h2>
                                <h3 className='mt-2'>{t("home.body.howItWorks.cards.review.subtitle")}</h3>
                            </div>
                            <div className="howItWorks2 xl:w-2/5 w-10/12  m-5 pt-12 pb-12 shadow-md bg-grayBGColor h-56 w-full">
                                <h2 className={`text-xl font-bold before:content-fileIcon before:pr-2 items-center flex`}>
                                    {t("home.body.howItWorks.cards.gather.title")}</h2>
                                <h3 className='mt-2'>{t("home.body.howItWorks.cards.gather.subtitle")}</h3>
                            </div>
                        </div>
                        <div className="flex flex-col xl:flex-row  items-center justify-center w-10/12">
                            <div className="howItWorks2 xl:w-2/5 w-10/12  m-5 p-10 shadow-md bg-grayBGColor w-full">
                                <h2 className={`text-xl font-bold before:content-shieldIcon before:pr-2 items-center flex break-keep`}>
                                    {t("home.body.howItWorks.cards.confront.title")}</h2>
                                <h3 className='mt-2 '>{t("home.body.howItWorks.cards.confront.subtitle")}</h3>
                            </div>
                            <div className="howItWorks2 xl:w-2/5 w-10/12  m-5 p-10 shadow-md bg-grayBGColor w-full">
                                <h2 className={`text-xl font-bold before:content-dollarCircleIcon before:pr-2 items-center flex`}>
                                    {t("home.body.howItWorks.cards.moneyBack.title")}</h2>
                                <h3 className='mt-2 '>{t("home.body.howItWorks.cards.moneyBack.subtitle")}</h3>
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </section>
            <section className='getFreeCons h-full bg-mainBGColor w-full flex flex-row pt-10 pb-10'>
                <Wrapper margins={false}>
                    <div className="flex flex-col xl:flex-row xl:h-96 items-center justify-center">
                        <div className="xl:freeCons flex flex-col justify-center xl:w-2/5 h-full items-center xl:items-start">
                            <h2 className='xl:text-5xl  text-3xl text-center xl:text-left font-bold whitespace-pre-wrap'>{t("home.body.moneyRetrieved.title")} <span className="text-lg">{t("home.body.moneyRetrieved.quarter")}</span></h2>
                            <div className='text-6xl font-bold block xl:hidden mt-5 text-accentFontColor'>
                                $5,000,459
                            </div>
                            <h3 className='mt-7 mb-7 text-center xl:text-left font-medium text-gray-300'>{t("home.body.moneyRetrieved.subtitle")}</h3>
                            <CustomButton filled={true} text={t("form.getFreeCons")} linkTo={'/contact-us'} click={true}/>
                        </div>
                        <div className='xl:pl-36 xl:w-2/5  hidden xl:block  text-6xl text-accentFontColor'>
                            $5,000,459
                        </div>
                    </div>
                </Wrapper>
            </section>
            <section className="WhyWorkWithUs flex">
                <Wrapper>
                    <div className="flex w-full flex-col xl:flex-row justify-around items-center">
                        <HandsSvg/>
                        <div className="freeCons flex flex-col justify-center w-10/12 xl:w-2/5 h-full items-center xl:items-start">
                            <h2 className='text-5xl text-secondFontColor text-left font-bold whitespace-pre-wrap'>{t("home.body.whyWorkWithUs.top.title")} </h2>
                            <h3 className='mt-7 mb-7 text-center xl:text-left text-grayFontColor'>
                                {t("home.body.whyWorkWithUs.top.subtitle")}
                            </h3>
                            <CustomButton filled={false} text={t("header.links.aboutUs.title")} linkTo={'/about-us'} styles={'border-mainBGColor border text-mainBGColor hover:text-fontColor hover:bg-mainBGColor w-full xl:w-auto'}/>
                        </div>
                    </div>
                    <div className="flex flex-col xl:flex-row-reverse w-full justify-around items-center mt-28">
                        <Guarante className='guarantee w-fit relative xl:right-20'/>
                        <div className="freeCons flex flex-col justify-center w-10/12 xl:w-2/5 h-full items-center xl:items-start">
                            <h2 className='text-5xl text-secondFontColor text-center xl:text-left font-bold whitespace-pre-wrap'>{t("home.body.whyWorkWithUs.bottom.title")} </h2>
                            <h3 className='mt-7 mb-7 text-center xl:text-left text-grayFontColor'>{t("home.body.whyWorkWithUs.bottom.subtitle")}</h3>
                            <Button linkTo='/tnc' filled={false} text={t("form.terms")} styles={'border-mainBGColor border text-mainBGColor hover:text-fontColor hover:bg-mainBGColor w-full xl:w-auto'}/>
                        </div>
                    </div>
                </Wrapper>
            </section>
            <section className='getFreeCons h-full bg-mainBGColor w-full flex flex-row pt-10 pb-10'>
                <Wrapper margins={false}>
                    <div className="flex h-96 items-center justify-center">
                        <div className="freeCons flex flex-col justify-center w-10/12 xl:w-2/4 h-full items-start">
                            <h2 className='text-5xl font-bold text-center mx-auto'>{t("home.body.moneyBack.title")} </h2>
                            <h3 className='mt-7 mb-7 text-center mx-auto'>{t("home.body.moneyBack.subtitle")}</h3>
                            <div className="chooseScamType flex flex-col w-8/12 mx-auto">
                                <TextField
                                    className='mainSelect'
                                    variant='filled'
                                    label={t("form.selecttypescam")}
                                    inputProps={{MenuProps: {disableScrollLock: true}}}
                                    onChange={handleValue}
                                    // className='px-9 py-3 mb-12 pr-2.5 text-white'
                                    value={type}
                                    select
                                    placeholder='Choose a scam type'>
                                    <MenuItem value={'Binary options'}>{t("header.links.recovery.contents.binary.title")}</MenuItem>
                                    <MenuItem value={'Cryptocurrency'}>{t("header.links.recovery.contents.crypto.title")}</MenuItem>
                                    <MenuItem value={'Forex'}>{t("header.links.recovery.contents.forex.title")}</MenuItem>
                                    <MenuItem value={'Stock Trading'}>{t("header.links.recovery.contents.stock.title")}</MenuItem>
                                    <MenuItem value={'Credit card'}>{t("header.links.recovery.contents.card.title")}</MenuItem>
                                    <MenuItem value={'Property Scams'}>{t("header.links.recovery.contents.property.title")}</MenuItem>
                                    <MenuItem value={'Romance Scam'}>{t("header.links.recovery.contents.romance.title")}</MenuItem>
                                    <MenuItem value={'Other Scams'}>{t("header.links.recovery.contents.other.title")}</MenuItem>
                                </TextField>

                                <Button filled={true} btnwidth={''} text={t("form.getFreeCons")} typeScam={type}/>
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </section>
            {/* <section className="certificates">
                <Wrapper>
                    <div className="carouselCertificate h-full text-center mt-12 mb-12">
                        <h2 className='ourClient text-4xl text-secondFontColor font-semibold'>
                            Certificates
                        </h2>
                        <div className="h-full">
                            <CustomCarousel widthContainer={'100%'} heightContainer={'100%'} sldShow={3} sldScroll={3}>
                                <img src={CySec} alt=""/>
                                <img src={CySec} alt=""/>
                                <img src={CySec} alt=""/>
                                <img src={CySec} alt=""/>
                                <img src={CySec} alt=""/>
                                <img src={CySec} alt=""/>
                                <img src={CySec} alt=""/>
                                <img src={CySec} alt=""/>
                                <img src={CySec} alt=""/>
                                <img src={CySec} alt=""/>
                                <img src={CySec} alt=""/>
                                <img src={CySec} alt=""/>
                            </CustomCarousel>
                        </div>
                    </div>
                </Wrapper>
            </section> */}
            <section className='getFreeCons h-full bg-mainBGColor w-full flex flex-row pt-16 pb-16'>
                <Wrapper margins={false}>
                    <div className="flex divCol-reverse xl:flew-row xl:h-96 items-center justify-center">
                        <div className="freeCons flex flex-col justify-center w-10/12 xl:w-2/5 h-full  items-center xl:items-start">
                            <h2 className='text-5xl text-center xl:text-left font-bold whitespace-pre-wrap'>
                                {t("home.body.howWeWork.title")}
                            </h2>
                            <h3 className='mt-7 text-center xl:text-left whitespace-pre-wrap'>
                                {t("home.body.howWeWork.subtitles.first")}
                            </h3>
                            <h3 className='mt-7 mb-7 text-center xl:text-left whitespace-pre-wrap'>
                                {t("home.body.howWeWork.subtitles.second")}
                            </h3>
                            <CustomButton filled={true} text={t("form.getFreeCons")} linkTo={'/contact-us'} click={true}/>
                        </div>
                        <div className='xl:pl-36 xl:w-2/5 text-6xl text-accentFontColor'>
                            <TeamSvg/>
                        </div>

                    </div>
                </Wrapper>
            </section>
            <section>
                <Wrapper>
                    <div className="flex flex-col items-center w-full">
                        <h2 className='text-secondFontColor text-4xl font-bold text-center'>
                            {t("home.body.typesScam.title")}
                        </h2>
                        <div className="flex  mt-20 xl:mt-0 xl:flex-col flex-row justify-center xl:mx-auto xl:w-5/12">
                            <div className="flex flex-col xl:flex-row typesOfScam xl:mt-20 xl:items-center xl:justify-center xl:mx-auto xl:w-6/12">
                                <Link onClick={()=>{window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}} to={'/binary-options-scam'} className="px-10 py-6 flex flex-col justify-center items-center text-center border text-mainBGColor border-mainBGColor hover:bg-mainBGColor hover:text-secondBGColor transition w-full  xl:py-6 xl:px-12 xl:min-w-full ">
                                    <Binary className='mb-3 hover:fill-amber-50'/>
                                    <h3 className='xl:text-xl  text-xs font-bold'>  {t("header.links.recovery.contents.binary.title")}</h3>
                                </Link>
                                <Link onClick={()=>{window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}} to={'/cryptocurrency-scam'}  className="px-10 py-6  flex flex-col justify-center items-center text-center  border text-mainBGColor border-mainBGColor hover:bg-mainBGColor hover:text-secondBGColor transition w-full xl:py-6 xl:px-12 xl:min-w-full ">
                                    <Cryptocurrency className='mb-3'/>
                                    <h3 className='xl:text-xl w-full  text-xs  font-bold'>{t("header.links.recovery.contents.crypto.title")}</h3>
                                </Link>
                                <Link onClick={()=>{window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}} to={'/forex-scam'} className="px-10 py-6 flex flex-col justify-center items-center  text-center border text-mainBGColor border-mainBGColor hover:bg-mainBGColor hover:text-secondBGColor transition w-full xl:py-6 xl:px-12 xl:min-w-full ">
                                    <Forex className='mb-3'/>
                                    <h3 className='xl:text-xl  text-xs  font-bold'>{t("header.links.recovery.contents.forex.title")}</h3>
                                </Link>
                                <Link onClick={()=>{window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}} to={'/romance-scam'}  className="px-10 py-6 flex flex-col justify-center items-center  text-center border text-mainBGColor border-mainBGColor hover:bg-mainBGColor hover:text-secondBGColor transition w-full xl:py-6 xl:px-12 xl:min-w-full ">
                                    <Romance className='mb-3'/>
                                    <h3 className='xl:text-xl  text-xs  font-bold'>{t("header.links.recovery.contents.romance.title")}</h3>
                                </Link>
                            </div>
                            <div className="flex flex-col xl:flex-row typesOfScam xl:justify-center xl:mx-auto xl:w-6/12">
                                <Link onClick={()=>{window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}} to={'/property-scam'}  className="px-10 py-6 flex flex-col justify-center items-center text-center  border text-mainBGColor border-mainBGColor hover:bg-mainBGColor hover:text-secondBGColor transition w-full xl:py-6 xl:px-12 xl:min-w-full ">
                                    <Property className='mb-3'/>
                                    <h3 className='xl:text-xl   text-xs  font-bold'>{t("header.links.recovery.contents.property.title")}</h3>
                                </Link>
                                <Link onClick={()=>{window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}} to={'/credit-card-scam'}  className="px-10 py-6 flex flex-col justify-center items-center  text-center border text-mainBGColor border-mainBGColor hover:bg-mainBGColor hover:text-secondBGColor transition w-full xl:py-6 xl:px-12 xl:min-w-full ">
                                    <Creditcard className='mb-3'/>
                                    <h3 className='xl:text-xl text-xs  font-bold'>{t("header.links.recovery.contents.card.title")}</h3>
                                </Link>
                                <Link onClick={()=>{window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}} to={'/stock-trading-scam'}  className="px-10 py-6 flex flex-col justify-center items-center  text-center border text-mainBGColor border-mainBGColor hover:bg-mainBGColor hover:text-secondBGColor transition w-full xl:py-6 xl:px-12 xl:min-w-full ">
                                    <Stocktrading className='mb-3'/>
                                    <h3 className='xl:text-xl   text-xs  font-bold'>{t("header.links.recovery.contents.stock.title")}</h3>
                                </Link>
                                <Link onClick={()=>{window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}} className="px-10 py-6 flex flex-col justify-center items-center  text-center border text-mainBGColor border-mainBGColor hover:bg-mainBGColor hover:text-secondBGColor transition w-full xl:py-6 xl:px-12 xl:min-w-full ">
                                    <Otherscams className='mb-3'/>
                                    <h3 className='xl:text-xl   text-xs  font-bold'>{t("header.links.recovery.contents.other.title")}</h3>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </section>
            <section className='moreAboutSite'>
                <Wrapper>
                    <h2 className='text-secondFontColor w-10/12 xl:w-6/12 mx-auto text-4xl font-bold text-center'>
                        {t("home.body.moreAboutUs.title").replace('siteName',siteName)}
                    </h2>
                    <div className="accordionSection  w-10/12 xl:w-6/12 mx-auto mt-10 xl:mt-20">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography> {t("home.body.moreAboutUs.one.title").replace('siteName',siteName)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <span className='detailsSpan'>
                                            {t("home.body.moreAboutUs.one.contents.1").replace('siteName',siteName)}
                                    </span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.one.contents.2").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.one.contents.3").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.one.contents.4").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.one.contents.5").replace('siteName',siteName)}</span>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>{t("home.body.moreAboutUs.two.title").replace('siteName',siteName)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.two.contents.1").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.two.contents.2").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.two.contents.3").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.two.contents.4").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.two.contents.5").replace('siteName',siteName)}</span>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>{t("home.body.moreAboutUs.three.title").replace('siteName',siteName)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.three.contents.1").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.three.contents.2").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.three.contents.3").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.three.contents.4").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.three.contents.5").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.three.contents.6").replace('siteName',siteName)}</span>

                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>{t("home.body.moreAboutUs.four.title").replace('siteName',siteName)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <Link to={'/forex-scam'} className='detailsSpan before:content-["■"] before:text-accentFontColor before:font-black before:mr-6 before:text-3xl'>
                                        <span className='underline'>{t("home.body.moreAboutUs.four.contents.key_points.first").replace('siteName',siteName)}</span>
                                    </Link>
                                    <span className='detailsSpan before:content-["■"] before:text-accentFontColor before:font-black before:mr-6 before:mb-6 before:text-3xl'>
                                        <span>{t("home.body.moreAboutUs.four.contents.key_points.second").replace('siteName',siteName)}</span>
                                    </span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.four.contents.contents.1").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.four.contents.contents.2").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.four.contents.contents.3").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.four.contents.contents.4").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.four.contents.contents.5").replace('siteName',siteName)}</span>

                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>{t("home.body.moreAboutUs.five.title").replace('siteName',siteName)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.five.contents.1").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.five.contents.2").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.five.contents.3").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.five.contents.4").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.five.contents.5").replace('siteName',siteName)}</span>
                                    <span className='detailsSpan'>{t("home.body.moreAboutUs.five.contents.6").replace('siteName',siteName)}</span>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Wrapper>
            </section>
        </>
    );
};

export default Main;