import React, {useEffect, useState} from 'react';
import { Routes, Route, Link } from "react-router-dom";
import { Main } from "../../pages/Main/index"
import { Testimonials } from "../../pages/Testimonials/index"
import {ReactComponent as ArrowIcon} from "../../images/icons/arrow-down-icon.svg";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from "react-i18next";


const Links = ({children, mobile=false, onClickLink}) => {
    const handleClickLink = (event) => {
        console.log('click')
        onClickLink(event)
    }
    const [expanded, setExpanded] = React.useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const [show, setShow] = useState(false)
    const [t, i18n] = useTranslation("global");
    const arrLinks = [
        {
            title: t("header.links.recovery.title"),
            link:t("header.links.recovery.link"),
            content:[{
                title: t("header.links.recovery.contents.binary.title"),
                link:   t("header.links.recovery.contents.binary.link")

            },
                {
                    title:t("header.links.recovery.contents.crypto.title"),
                    link:t("header.links.recovery.contents.crypto.link")
                },
                {
                    title:t("header.links.recovery.contents.forex.title"),
                    link:t("header.links.recovery.contents.forex.link")
                },
                {
                    title:t("header.links.recovery.contents.stock.title"),
                    link:t("header.links.recovery.contents.stock.link")
                },
                {
                    title:t("header.links.recovery.contents.card.title"),
                    link:t("header.links.recovery.contents.card.link")
                },
                {
                    title:t("header.links.recovery.contents.property.title"),
                    link:t("header.links.recovery.contents.property.link")
                },
                {
                    title:t("header.links.recovery.contents.romance.title"),
                    link:t("header.links.recovery.contents.romance.link")
                },
                {
                    title:t("header.links.recovery.contents.other.title"),
                    link:t("header.links.recovery.contents.other.link")
                }
            ]
        },
        {
            title: t("header.links.contactUs.title"),
            link:t("header.links.contactUs.link"),
        },
        {
            title: t("header.links.aboutUs.title"),
            link:t("header.links.aboutUs.link"),
        }
    ]

    const LinksFromEnv = () => {
        return arrLinks.map((link,key)  => {
            if(link.content && mobile) {
                return (
                    <>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>{link.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <span className='detailsSpan'>
                                        <ul>
                                            {link.content.map((accordionLinks,key) =>(
                                                <li className={`flex flex-col`}>
                                                    <Link key={key} onClick={(event)=>{window.scrollTo({top: 0, left: 0, behavior: 'smooth'}); handleClickLink(event) }} className='py-2 px-3 relative text-mainBGColor' to={accordionLinks.link.toLowerCase().replace(/ /g,"-").concat('-scam')}>{accordionLinks.title}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </span>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </>
                )
            }
            if(link.content) {
                return (
                    <>
                        <div key={key} className={`mx-2.5  flex accordDiv  text-gray-400 transition hover:text-white hover:cursor-pointer relative pr-4  pb-2 pt-2`} onMouseEnter={(select)=>{
                            setShow(true)
                        }}
                             onMouseLeave={()=>{
                                 setShow(false)
                             }}>

                            {link.title}
                            <ul className={`flex flex-col ${show ? 'h-auto w-full' : 'h-0 w-0'} bg-secondBGColor mt-8  transition absolute left-0`}>
                                {link.content.map((accordionLinks,key) =>(
                                    <li className={`${show ? 'flex flex-col' : 'hidden'}`}>
                                        <Link key={key} onClick={(event)=>{window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}} className='py-2 px-3 relative text-mainBGColor whitespace-nowrap transition-colors border-l-4 border-l-mainBGColor hover:border-l-accentFontColor' to={accordionLinks.link.toLowerCase().replace(/ /g,"-").concat('-scam')}>{accordionLinks.title}</Link>
                                    </li>
                                ))}
                            </ul>
                            <ArrowIcon className={`arrowSvg  ml-1.5`} />
                        </div>
                    </>
                )
            }
            if (!mobile) {
                return (
                    <>
                        <Link key={key} onClick={(event)=>{window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}} to={link.link.toLowerCase().replace(" ","-")} className='mx-2.5 text-gray-400 hover:text-white'>
                            {link.title}
                        </Link>
                    </>
                )
            }
            return (
                <>
                    <ul className='flex flex-col'>
                        <li className='ml-5 py-2 px-3 relative text-mainBGColor text-xl font-bolder'>
                            <Link onClick={(event)=> {
                                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                                handleClickLink(event)

                            }} key={key} to={link.link.toLowerCase().replace(" ","-")} className='font-bolder'>
                                {link.title}
                            </Link>
                        </li>
                    </ul>
                </>
            )
        });
    }
    return (
        <>
            <LinksFromEnv/>
        </>
    );
};

export default Links;