import React, {useEffect, useState} from 'react';
import {ReactComponent as CloseIcon} from "../../images/icons/burger-close-white.svg";
import {Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField} from "@mui/material";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from "axios";
import Swal from 'sweetalert2'
import {useForm} from 'react-hook-form'
import {useTranslation} from "react-i18next";

const FormWithType = ({scamType = '', click}) => {
    const [open, setOpen] = useState(click)
    const [title, setTitle] = useState(['How much did you invest?','How did you transfer the money?','Anything else we should know about?','How can we reach you?'])
    const [much,setMuch] = useState('')
    const [phone, setPhone] = useState();
    const [ip, setIp] = useState("");
    const [city,setCity] = useState("")
    const [formatPhone,setFormatPhone] = useState(0)
    const [country,setCountry] = useState('')
    const { register, handleSubmit, reset  } = useForm();
    const [lang,setLang] = useState('en')
    const [curIp,setCurIp] = useState()
    const [t, i18n] = useTranslation("global")
    useEffect(() => {
        axios.get("https://ipapi.co/json")
            .then(res => res)
            .then(data => {
                setCity(data.data.city)
                setIp(data.data.country)
                setPhone(data.data.country_calling_code)
                setCountry(data.data.country_name)
                setCurIp(data.data.ip)
            })
            .catch((err) => console.log(err));

    },[])
    const [stage,setStage] = useState({stage: 0, maxStage:3, next:'How did you transfer the money?', prev:'', prevValue: '',nextValue:''})

    const handleRadio = (value) => {
        setMuch(value.target.value)
    }
    const handleClose = () => {
        if (stage.stage !== 0 ) {
            Swal.fire({
                title: "Are you sure you want to close this window?",
                text:"All entered data will be lost",
                showDenyButton: false,
                showConfirmButton:true,
                showCancelButton: true,
                confirmButtonText: "Yes,close",
                color: '#030e31',
                background: '#f4f4f4;',
            }).then((result) => {
                if (result.isConfirmed) {
                    setOpen(!open);
                    document.body.classList.remove('hideScrollY')
                    click(!click)
                }
            });
            return false
        }
        setOpen(!open);
        document.body.classList.remove('hideScrollY')
        click(!click)
    };
    const handleClickNext = (event) => {

        setStage( (prev)=> {
            return {
                ...prev,
                stage: prev.stage >= prev.maxStage ? prev.maxStage : prev.stage + 1
            }
        })
    }
    const handleClickPrev = () => {
        if (stage.stage === 0 ) {
            handleClose()
        }
        // prevStage()
        setStage((lastStage)=> {
            return {
                ...lastStage,
                stage: lastStage.stage <= 0 ? 0 : lastStage.stage - 1
            }
        })

    }

    const handlePhone = (value, data, event, formattedValue) => {
        let phoneFormatValue = value.replace(data.dialCode,"'+(" + data.dialCode + ")")
        setFormatPhone(phoneFormatValue)
        setPhone(value)
    }
    const todayData = new Date().toLocaleDateString();
    const todayTime = new Date().toLocaleTimeString();
    const currentUrl = window.location.href;
    const addToPathSend = window.location.pathname == '/' ? 'sended' : '/sended'
    const fullData = todayData + '_' + todayTime
    const formSubmit = (data) => {
        if (phone == undefined || formatPhone.length < 7 || phone == null || phone == '') {
            Swal.fire({
                text: "Please type your phone number",
                icon: "error"
            });
            return false
        }
        const formData = new FormData()
        formData.append('Name', data.Name)
        formData.append('Lname', data.LName)
        formData.append('EMail', data.EMail)
        formData.append('Phone', formatPhone)
        formData.append('Time', fullData)
        formData.append('Country', country)
        formData.append('HowMuch', much)
        formData.append('IP', curIp)
        formData.append('Source', window.location.href)
        formData.append('Language', i18n.resolvedLanguage)

        formData.append('Case', '[' + scamType + ']' + '__' + data.Case)


        fetch('https://script.google.com/macros/s/AKfycbwBXDor5WCx0jS3wNe1PPmc83Ou_pKH0Xs8K63XxEyULuvjPhmk-ER4O_BL--LMNuU/exec',{
            method: "POST",
            body: formData
        }).then((e) => {
            reset()
            setPhone('')
            setMuch('')
            setOpen(!open);
            document.body.classList.remove('hideScrollY')
            click(!click)
            Swal.fire({
                title: "Success",
                text: "Expect a call from a staff member",
                icon: "success"
            });
            window.history.replaceState(null, null, currentUrl + addToPathSend);
        })
    }
    useEffect(()=>{
        if(click) {
            setOpen(click)
        }
    },[])

    return (
        <div className={`relative  formWithType  ${ click ? 'open' : ''}`}>
            <div className={`grayBG ${ click ? 'open' : ''}`}>
                <div className={`bg-secondBGColor formWithTypeDiv ${ click ? 'open' : ''} w-full xl:w-4/12`}>
                    <form onSubmit={handleSubmit(formSubmit)} className='h-full'>
                        <div className='flex flex-col w-full h-full  items-end justify-start '>
                            <div className='flex flex-col w-full h-full items-start justify-start'>
                                <Box className='w-full  '>

                                    <Box className="flex justify-between items-center w-full bg-secondBGColor pl-12 pr-8 pt-7 pb-7 ">
                                        <FormLabel
                                            sx={{fontSize:'1.2rem',fontWeight:'bold',color:'text-accentFontColor'}}
                                            className='mt-1 font-bold text-2xl text-accentFontColor'
                                            focused={false}
                                        >
                                            {title[stage.stage]}
                                        </FormLabel>
                                        <CloseIcon onClick={handleClose} className='top-0 right-0 changeSVG relative text-mainBGColor float-right fill-black' />
                                    </Box>
                                    <Box className="pl-12 mt-12 w-full">
                                        <FormControl className={'w-10/12'}>
                                            <RadioGroup className={`${stage.stage === 0 ? 'block opacity-100' : 'hidden'} fade radioButtons `} onChange={(value)=>{handleRadio(value)}}>
                                                <FormControlLabel className={'text-secondFontColor'} value="Less than $5,000" control={<Radio />} label={t("form.howMuch.options.1")}/>
                                                <FormControlLabel className={'text-secondFontColor'} value="$5,000 - $10,000" control={<Radio />} label={t("form.howMuch.options.2")} />
                                                <FormControlLabel className={'text-secondFontColor'} value="$10,000 - $20,000" control={<Radio />} label={t("form.howMuch.options.3")}/>
                                                <FormControlLabel className={'text-secondFontColor'} value="$20,000 - $40,000" control={<Radio />} label={t("form.howMuch.options.4")} />
                                                <FormControlLabel className={'text-secondFontColor'} value="$80,000 - $160,000" control={<Radio />} label={t("form.howMuch.options.5")} />
                                                <FormControlLabel className={'text-secondFontColor'} value="$160,000 and up" control={<Radio />} label={t("form.howMuch.options.6")} />
                                            </RadioGroup>
                                            <RadioGroup className={`${stage.stage === 1 ? 'block opacity-100' : 'hidden'} fade radioButtons `}>
                                                <FormControlLabel className={'text-secondFontColor'} value="Credit card/Debit card" control={<Radio />} label="Credit card/Debit card" />
                                                <FormControlLabel className={'text-secondFontColor'} value="BTC/Crypto" control={<Radio />} label="BTC/Crypto" />
                                                <FormControlLabel className={'text-secondFontColor'} value="Wire Transfer" control={<Radio />} label="Wire Transfer" />
                                                <FormControlLabel className={'text-secondFontColor'} value="Money Gram / Western Union" control={<Radio />} label="Money Gram / Western Union" />
                                                <FormControlLabel className={'text-secondFontColor'} value="Cash" control={<Radio />} label="Cash" />
                                                <FormControlLabel className={'text-secondFontColor'} value="Other" control={<Radio />} label="Other" />
                                            </RadioGroup>
                                            <TextField
                                                className={`${stage.stage === 2 ? 'flex opacity-100' : 'hidden'} w-full fade`}
                                                multiline={true}
                                                rows={6}
                                                placeholder='Outline your case'
                                                inputProps={register('Case')}
                                            >
                                            </TextField>
                                            <Box className={`${stage.stage === 3 ? 'block opacity-100' : 'hidden'} fade `}>
                                                <Box className={'flex'}>
                                                    <TextField
                                                        style={{width:'100%', marginBottom:'0.5rem', marginRight:'0.5rem'}}
                                                        type="text"
                                                        name='firstName'
                                                        required={true}
                                                        inputProps={register('Name')}
                                                        label={t("form.firstName")}
                                                        variant="outlined"
                                                    />

                                                    <TextField
                                                        style={{width:'100%', marginBottom:'0.5rem'}}
                                                        type="text"
                                                        name="lastName"
                                                        inputProps={register('LName')}
                                                        required={true}
                                                        label={t("form.lastName")}
                                                        variant="outlined"
                                                    />
                                                </Box>
                                                <Box className={'flex flex-col w-full items-start'}>
                                                    <TextField
                                                        style={{width:'100%', marginBottom:'0.5rem',paddingBottom:'0.2rem'}}
                                                        type="email"
                                                        label="Enter Your Email"
                                                        inputProps={register('EMail')}
                                                        variant="outlined"
                                                    />
                                                    <PhoneInput
                                                        inputProps={{
                                                            register: 'Phone',
                                                            required: true,
                                                            name:'phone'
                                                        }}
                                                        specialLabel={t("form.phone")}
                                                        value={phone}
                                                        enableLongNumbers={true}
                                                        inputClass={'tel-input'}
                                                        dropdownClass={'tel-dropdown'}
                                                        containerClass={'tel-container'}
                                                        enableAreaCode={true}
                                                        countryCodeEditable={false}
                                                        onChange={handlePhone}
                                                        country={ip.toLowerCase()}
                                                    />

                                                </Box>
                                            </Box>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </div>
                            <Box className="flex relative bottom-16 right-16">
                                <Button
                                    onClick={handleClickPrev}
                                >
                                    {stage.stage === 0 ? "Cancel" : "Back"}
                                </Button>
                                <Button
                                    sx={{marginLeft:'1rem'}}
                                    variant={'contained'}
                                    className={`py-3 px-9 bg-accentFontColor hover:bg-accentHoverColor border-transparent text-black border-2 text-center font-semibold  transition `}
                                    value={stage.stage}
                                    onClick={handleClickNext}
                                    type={stage.stage === 3 ? 'submit' : 'button'}
                                >
                                    {stage.stage !== 3 ? "Next" : "Send"}
                                </Button>
                            </Box>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
};

export default FormWithType;